import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { marked } from 'marked';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BlogPost = () => {
  const [post, setPost] = useState('');
  const { slug } = useParams();

  useEffect(() => {
    fetch(`/posts/${slug}.md`)
      .then(response => response.text())
      .then(text => {
        const content = marked(text);
        setPost(content);
      })
      .catch(error => console.error('Error loading blog post:', error));
  }, [slug]);

  return (
    <div className="blog-post-container">
      <Link to="/blog" className="back-link">
        <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
        Back to Blog
      </Link>
      <div className="blog-post">
        <div dangerouslySetInnerHTML={{ __html: post }} />
      </div>
    </div>
  );
};

export default BlogPost;
