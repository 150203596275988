import React from 'react';
import { Link } from 'react-router-dom';
import { FaTools } from 'react-icons/fa';
import { projects } from '../data/projectData';

function Projects() {
  return (
    <section className="projects-section" id="projects">
      <div className="projects-banner">
        <h2>Projects</h2>
        <FaTools className="icon" />
      </div>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div key={index} className="project" style={{ backgroundImage: `url(${project.image})` }}>
            <div className="project-content">
              <h3>{project.name}</h3>
              <p>{project.description}</p>
              <Link to={project.link} className="project-link">
                Learn More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;