import React from 'react';
import Lottie from 'lottie-react';
import hiAnimation from '../animations/Hi.json';
import Passions from './Passions';

const Greeting = () => {
  return (
    <div className="greeting-container">
      <div className="greeting-main">
        <Lottie 
          className="hi-animation" 
          animationData={hiAnimation} 
          loop={true}
        />
        <h2 className="greeting-text">Hi, I'm Marco</h2>
      </div>
      <p className="brief-description greeting-text larger-text">
      Now leading the software division of a space-focused startup,
      I'm driven by a passion for embedded systems, robotics, and continuous innovation. Let's connect!
      </p>
      <Passions />
    </div>
  );
};

export default Greeting;
