import React, { useEffect } from 'react';
import ProjectTemplate from './ProjectTemplate';

const MyFirstWebProject = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const project = {
    name: 'My First Web Development Project',
    description: `As I embark on my journey into web development, this project marks my first experience creating a web application. For the frontend, I’m using React alongside plain CSS, JavaScript, and HTML. I’m intentionally avoiding the use of frameworks for now, but I plan to transition to Tailwind, Bootstrap, or Material UI in the future as I grow more comfortable. Currently, the project doesn’t have a backend, but as I continue learning, I’ll definitely be adding one—likely using either Java or Go. My goal is to write articles, recommend books, and keep this project open-source to document and share my learning process.`,
    image: '',
    technologies: ['React', 'CSS', 'JavaScript', 'HTML', 'Frontend Development'],
    features: [
      'First experience with React for frontend development',
      'Styling with plain CSS',
      'JavaScript used for dynamic behavior',
      'HTML for structuring content',
      'Exploration of web development fundamentals',
      'Plans to add a backend in Java or Go',
      'Future enhancements with Tailwind, Bootstrap, or Material UI',
      'Open-source project aimed at sharing knowledge and resources'
    ],
    demoLink: 'https://example.com/my-first-web-project-demo',
    githubLink: 'https://github.com/yourusername/my-first-web-project'
  };

  return <ProjectTemplate project={project} />;
};

export default MyFirstWebProject;
