export const books = [
  {
    title: "Steve Jobs",
    author: "Walter Isaacson",
    description: "The exclusive biography of Steve Jobs, based on more than forty interviews with Jobs conducted over two years. Probably one of my favourites books of all time.",
    cover: "/img/Steve.jpg",
    genre: "Biography",
    rating: 5
  },
  {
    title: "Elon Musk",
    author: "Walter Isaacson",
    description: "The definitive biography of Elon Musk, the CEO of SpaceX and Tesla, Inc. Way better than the first one.",
    cover: "/img/Musk.jpg",
    genre: "Biography",
    rating: 4
  },
  {
    title: "Outliers",
    author: "Malcolm Gladwell",
    description: "A book that explores the factors that contribute to success, based on the author's research and observations.",
    cover: "/img/outliers.jpg",
    genre: "Psychology",
    rating: 4
  },
  {
    title: "PHI",
    author: "Giulio Tononi",
    description: "Giulio Tononi explores the nature of consciousness through a blend of science, philosophy, and art. It takes readers on a journey to understand how consciousness arises from the brain's intricate processes, introducing phi as a measure of integrated information. This thought-provoking book offers deep insights into the mind and soul.",
    cover: "/img/Phi.jpg",
    genre: "Science",
    rating: 4
  },
  {
    title: "The man who mistook his wife for a hat",
    author: "Oliver Sacks",
    description: "The man who mistook his wife for a hat is a collection of case studies of neurological patients, written by neurologist Oliver Sacks. Each chapter explores a different neurological condition and its effect on the patient's perception and behavior. Really intresting to understand how the brain works.",
    cover: "/img/hat.png",
    genre: "Science",
    rating: 4
  },
  {
    title: "Il Processo",
    author: "Franz Kafka",
    description: "Il Processo is a novel written by Franz Kafka, published in 1915. It tells the story of Josef K., a man who is arrested and tried for a crime he may or may not have committed. The novel explores themes of bureaucracy, justice, and the human condition. Nice one",
    cover: "/img/processo.jpg",
    genre: "Fiction",
    rating: 3
  },
  {
    title: "Madame Bovary",
    author: "Gustave Flaubert",
    description: "Madame Bovary is a novel written by Gustave Flaubert, published in 1857. It tells the story of Emma Bovary, a woman who dreams of a more exciting life than the one she has with her husband, Charles. The novel explores themes of love, desire, and the pursuit of happiness.",
    cover: "/img/madame.jpg",
    genre: "Fiction",
    rating: 2
  },
  {
    title : "Il fu Mattia Pascal",
    author : "Luigi Pirandello",
    description : "Main Theme: people are not who they seem to be. It's a story about a man who returns to his hometown after many years away and finds that his family and friends have changed. The novel explores themes of nostalgia, the passage of time, and the human condition.",
    cover : "/img/mattia.jpg",
    genre : "Fiction",
    rating : 3
  },
  {
    title : "Cosi parlò Zaratustra",
    author : "Friedrich Nietzsche",
    description : "Havent read it yet, but it's supposed to be good",
    cover : "/img/zara.jpeg",
    genre : "Philosophy",
    rating : 0
  },
  {
    title : "Fahrenait 451",
    author : "Ray Bradbury",
    description : "Havent read it yet, but it's supposed to be good",
    cover : "/img/fahrenait.png",
    genre : "Fiction",
    rating : 0
  },
  {
    title : "Brave new world",
    author : "Aldous Huxley",
    description : "Probably Huxley was a fun of Ford really nice book",
    cover : "/img/brave.jpg",
    genre : "Fiction",
    rating : 4
  },
  {
    title : "Un Amore",
    author: "Dino Buzzati",
    description : "Un Amore is a novel written by Dino Buzzati, published in 1942. It tells the story of a man who falls in love with a woman who is not his wife. The novel explores themes of love, marriage, and the human condition. If you want a soft erotic book this is the right one",
    cover : "/img/amore.jpg",
    genre : "Fiction",
    rating : 3
  },
  {
    title : "Man without a woman",
    author : "Murakami Haruki",
    description : "Man without a woman is a novel written by Murakami Haruki, published in 1987. It tells the story of a man who falls in love with a woman who is not his wife. The novel explores themes of love, marriage, and the human condition.",
    cover : "/img/woman.jpg",
    genre : "Fiction",
    rating : 2
  },
  {
    title : "The three body problem",
    author : "Liu Cixin",
    description : "Better thn the netflix serie",
    cover : "/img/three.jpg",
    genre : "Science Fiction",
    rating : 3
  },
  {
   title: "Thinking fast and slow",
   author: "Daniel Kahneman",
   description: "Really Boring the same concept repeated over and over again",
   cover : "/img/thinking.jpg",
   genre : "Psychology",
   rating : 1
  },
  {
    title: "When",
    author: "Daniel Pink",
    description: "Obvious book, but still true",
    cover : "/img/when.png",
    genre : "Self-Help",
    rating : 2
  },
  {
    title: "Eros e Pathos",
    author: "Aldo Carotenuto",
    description: "Eros e Pathos is a book written by Aldo Carotenuto, published in 2013. It is a philosophy book that explores the nature of love and the human condition. The book explores themes of love, marriage, and the human condition.",
    cover : "/img/eros.png",
    genre : "Philosophy",
    rating : 3
  },
  {
    title: "Crucial Conversations",
    author: "Kerry Patterson",
    description: "Nice book but is better the one of Chris Voss",
    cover : "/img/crucial.jpg",
    genre : "Self-Help",
    rating : 2
  },
  {
    title: "The parasitic mind",
    author: "Gad Saad",
    description: "Really controversial book, but still a nice read",
    cover : "/img/parasite.jpg",
    genre : "Psychology",
    rating : 3
  },
  {
    title: "A random walk down wall street",
    author: "Burton Malkiel",
    description: "A random walk down wall street is a book written by Burton Malkiel, published in 1973. It is a finance book that explores the nature of the stock market. The book explores themes of finance, the stock market, and the human condition.",
    cover : "/img/random.jpg",
    genre : "Finance",
    rating : 4
  },
  {
    title: "The lean startup",
    author: "Eric Ries",
    description: "Havent read it yet, but it's supposed to be good",
    cover : "/img/lean.jpeg",
    genre : "Business",
    rating : 0
  },
  {
    title: "From zero to one",
    author: "Peter Thiel",
    description: "From zero to one is a book written by Peter Thiel, published in 2014. It is a business book that explores the nature of startups. The book explores themes of business, startups, and the human condition.",
    cover : "/img/zero.jpg",
    genre : "Business",
    rating : 4
  },
  {
    title: "Leading Change",
    author: "John P. Kotter",
    description: "Leading Change is a book written by John P. Kotter, published in 2012. It is a business book that explores the nature of leadership. The book explores themes of leadership, change, and the human condition.",
    cover : "/img/leading.jpg",
    genre : "Business",
    rating : 3
  },
  {
    title: "17 equations that changed the world",
    author: "Ian Stewart",
    description: "17 euations that changed the world is a book written by Ian Stewart, published in 2013. It is a science book that explores the nature of science. The book explores themes of science, the human condition, and the human mind.",
    cover : "/img/equations.jpg",
    genre : "Science",
    rating : 3
  },
  {
    title: "Mentire con le statistiche",
    author: "Darrel Huff  ",
    description: "Mentre con le statistiche is a book written by Darrel Huff, published in 1954. It is a science book that explores the nature of statistics. The book explores themes of statistics, the human condition, and the human mind.",
    cover : "/img/statistiche.jpg",
    genre : "Science",
    rating : 4
  },
  {
    title: "Quantum mechanics",
    author: "Leonard Susskind",
    description: "Nice introduction to quantum mechanics",
    cover : "/img/quantum.jpg",
    genre : "Science",
    rating : 4
  },
  {
    title: "Introduction to space dynamics",
    author: "William Thomson  ",
    description: "Nice because oit has exercises",
    cover : "/img/space.jpg",
    genre : "Science",
    rating : 3
  },
  {
    title: "The annotated turing",
    author: "Charles Petzold",
    description: "book about the famous paper of turing",
    cover : "/img/turing.jpg",
    genre : "Science",
    rating : 5
  },
  {
    title: "The rust programming language",
    author: "Steve Klabnik and Carol Nichols",
    description: "Nice to have but it is also free on the internet",
    cover : "/img/rust.jpg",
    genre : "Programming",
    rating : 5
  },
  {
    title: "System Design Interview - An Insider's Guide",
    author: "Alex Xu",
    description: "Nice For preparing interviews, both volume 1 and 2 are good",
    cover : "/img/system.jpg",
    genre : "Programming",
    rating : 4
  },
  {
    title: "Clean architecture",
    author: "Robert C. Martin",
    description: "If you enjoyed Clean Code you will love this book",
    cover : "/img/clean.jpg",
    genre : "Programming",
    rating : 3
  },
  {
    title: "Learning Go",
    author: "Jon Bodner",
    description: "Standard book, nothing special",
    cover : "/img/go.jpg",
    genre : "Programming",
    rating : 3
  },
  {
    title: "Design your life",
    author: "Bill Burnett and Dave Evans",
    description: "obvious book",
    genre : "Self-Help",
    rating : 2
  },
  {
    title: "The 5am club",
    author: "Robin Sharma",
    description: "Nice book but applied it for a few days and then stopped",
    cover : "/img/five.jpg",
    genre : "Self-Help",
    rating : 3
  },
  {
    title: "How to win friends and influence people",
    author: "Dale Carnegie",
    description: "A classic book, but it's better to read it in the original language",
    cover : "/img/friends.jpg",
    genre : "Self-Help",
    rating : 3
  },
  {
    title: "The art of extraordinary confidence",
    author: "Aziz Gazipura",
    description: "In italy we say fuffa.",
    cover : "/img/confidence.jpg",
    genre : "Self-Help",
    rating : 1
  },

];
