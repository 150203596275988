import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { books } from '../data/bookData';

const RecommendedBooks = ({ id, showAll = false }) => {
  const getRandomBooks = (count) => {
    const shuffled = [...books].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const displayedBooks = showAll ? books : getRandomBooks(3);

  const renderRating = (rating) => {
    const roundedRating = Math.floor(rating);
    return (
      <div className="book-rating">
        {[...Array(5)].map((_, index) => (
          <FontAwesomeIcon
            key={index}
            icon={faStar}
            className={index < roundedRating ? "star filled" : "star"}
          />
        ))}
        <span className="rating-number">({rating}/5)</span>
      </div>
    );
  };

  return (
    <section id={id} className="recommended-books-section">
      <div className="section-banner">
        <h2>Recommended Books</h2>
        <FontAwesomeIcon icon={faBook} className="icon" />
      </div>
      <div className="books-container">
        {displayedBooks.map((book, index) => (
          <div key={index} className="book-item">
            <div className="book-cover">
              {book.cover ? (
                <img src={book.cover} alt={book.title} />
              ) : (
                <FontAwesomeIcon icon={faBook} className="default-cover" />
              )}
            </div>
            <div className="book-content">
              <h3>{book.title}</h3>
              <h4>{book.author}</h4>
              {renderRating(book.rating)}
              <p>{book.description}</p>
            </div>
          </div>
        ))}
      </div>
      {!showAll && (
        <div className="see-all-container">
          <Link to="/books" className="see-all-books">
            See All Books <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      )}
    </section>
  );
};

export default RecommendedBooks;