import React, { useState, useContext } from 'react';
import { ThemeContext } from '../components/ThemeProvider';
import { FaPaperPlane } from 'react-icons/fa';

function ContactForm() {
  const { theme } = useContext(ThemeContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear error when user starts typing
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) {
      formErrors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email is invalid';
    }
    if (formData.message.trim().length < 10) {
      formErrors.message = 'Message must be at least 10 characters long';
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      try {
        // Your form submission logic here
        // For example:
        // await submitForm(formData);
        setStatusMessage('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } catch (error) {
        setStatusMessage('Failed to send message. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className={`contact-form ${theme}`}>
      {/* Removed all content */}
    </div>
  );
}

export default ContactForm;