import React, { useEffect } from 'react';
import ProjectTemplate from './ProjectTemplate';

const RobotNavigationProject = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const project = {
    name: 'Autonomous Multi-Agent System Navigation',
    description: `This project was part of a multi-agent system competition where an unmanned aerial vehicle (UAV) and an unmanned ground vehicle (UGV) had to cooperate in GPS-denied, critical environments. The competition simulated an urban setting, and the two agents collaborated to create a shared map using a proprietary SLAM algorithm, ARTSLAM, which is graph-based. The entire software stack was developed in C++ and ROS2, fully containerized with Docker, and included custom kernels to run computer vision models on monocular cameras. We leveraged sensor fusion through an IMU to obtain optimal position estimates. The ground vehicle featured omnidirectional kinematics, and we developed a control panel using QT for real-time updates on vehicle status. We consistently achieved first place for several years, outperforming other Italian universities.`,
    image: '/img/LeoWork.webp',
    technologies: ['ROS2', 'C++', 'SLAM', 'Path Planning', 'Docker', 'Sensor Fusion', 'IMU', 'Omnidirectional Kinematics', 'Computer Vision'],
    features: [
      'Real-time proprietary SLAM (ARTSLAM) for shared environment mapping',
      'Robust path planning and obstacle avoidance',
      'Integration with UAV and UGV systems',
      'Custom Dockerized stack with specialized kernels',
      'Sensor fusion for enhanced position estimation',
      'Omnidirectional kinematics for the ground vehicle',
      'Control panel with QT'
    ],
    demoLink: 'https://example.com/robot-navigation-demo',
    githubLink: 'https://github.com/yourusername/robot-navigation-project'
  };

  return <ProjectTemplate project={project} />;
};

export default RobotNavigationProject;
