import React, { useEffect } from 'react';
import ProjectTemplate from './ProjectTemplate';

const DynamisProject = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const project = {
    name: 'Dynamis Formula SAE Steering Wheel Firmware',
    description: `As a Firmware Engineer for the Dynamis Formula SAE team at Politecnico di Milano, I developed the software for the steering wheel of the race car. The steering wheel, equipped with an LTDC screen, plays a crucial role as an in-car debugger, displaying all the signals circulating in various CAN buses and facilitating communication between the driver and the vehicle. The entire system operates in real-time, achieved through the use of an RTOS, and various techniques such as DMA were employed to ensure proper functioning and performance. I utilized an STM32 MCU, programmed in C/C++, and used Keil as the compiler. A key challenge was managing embedded graphics, which was an interesting aspect of the project, along with extensive use of oscilloscopes and various debuggers for troubleshooting embedded systems. At the end of the season, the car competed in four competitions and was ranked as the best in Italy.`,
    image: '/img/car2.jpg',
    technologies: ['STM32', 'C/C++', 'Keil', 'CAN Bus', 'LTDC', 'Embedded Graphics', 'RTOS', 'DMA', 'Oscilloscope Debugging'],
    features: [
      'Development of firmware for the steering wheel with LTDC display',
      'Real-time operation using RTOS',
      'Utilization of DMA and other techniques for reliable performance',
      'Real-time debugging through CAN bus signal monitoring',
      'Driver-vehicle communication interface',
      'Embedded graphics management',
      'Use of oscilloscopes and debuggers for embedded system troubleshooting',
      'Successful participation in four competitions, ranked best in Italy'
    ],
    demoLink: 'https://example.com/dynamis-steering-demo',
    githubLink: 'https://github.com/yourusername/dynamis-project'
  };

  return <ProjectTemplate project={project} />;
};

export default DynamisProject;
