import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFilter, faStar } from '@fortawesome/free-solid-svg-icons';
import { books } from '../data/bookData';

const BooksPage = () => {
  const [selectedGenre, setSelectedGenre] = useState('All');
  const [filteredBooks, setFilteredBooks] = useState(books);
  const [genres, setGenres] = useState(['All']);

  useEffect(() => {
    const uniqueGenres = ['All', ...new Set(books.map(book => book.genre))];
    setGenres(uniqueGenres);
  }, []);

  useEffect(() => {
    if (selectedGenre === 'All') {
      setFilteredBooks(books);
    } else {
      setFilteredBooks(books.filter(book => book.genre === selectedGenre));
    }
  }, [selectedGenre]);

  const handleGenreChange = (e) => {
    setSelectedGenre(e.target.value);
  };

  const renderRating = (rating) => {
    const roundedRating = Math.floor(rating);
    return (
      <div className="book-rating">
        {[...Array(5)].map((_, index) => (
          <FontAwesomeIcon
            key={index}
            icon={faStar}
            className={index < roundedRating ? "star filled" : "star"}
          />
        ))}
        <span className="rating-number">({rating}/5)</span>
      </div>
    );
  };

  return (
    <div className="books-page">
      <section id="books" className="recommended-books-section">
        <div className="section-banner">
          <h2>
            Recommended Books
            <FontAwesomeIcon icon={faBook} className="icon" style={{ marginLeft: '10px' }} />
          </h2>
        </div>
        <div className="genre-filter">
          <FontAwesomeIcon icon={faFilter} className="filter-icon" />
          <select value={selectedGenre} onChange={handleGenreChange} className="genre-select">
            {genres.map((genre, index) => (
              <option key={index} value={genre}>{genre}</option>
            ))}
          </select>
        </div>
        <div className="books-container">
          {filteredBooks.map((book, index) => (
            <div key={index} className="book-item">
              <div className="book-cover">
                {book.cover ? (
                  <img src={book.cover} alt={book.title} />
                ) : (
                  <FontAwesomeIcon icon={faBook} className="default-cover" />
                )}
              </div>
              <div className="book-content">
                <h3>{book.title}</h3>
                <h4>{book.author}</h4>
                {renderRating(book.rating)}
                <p>{book.description}</p>
                <span className="book-genre">{book.genre}</span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default BooksPage;