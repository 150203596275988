import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaExternalLinkAlt, FaGithub } from 'react-icons/fa';

const ProjectTemplate = ({ project }) => {
  return (
    <div className="project-page">
      <div className="project-header">
        <Link to="/" className="back-link">
          <FaArrowLeft /> Back to Projects
        </Link>
        <h1>{project.name}</h1>
      </div>
      
      <div className="project-container">
        <div className="project-details">
          <p className="project-description">{project.description}</p>
          
          <div className="project-section">
            <h2>Technologies Used</h2>
            <ul className="technologies-list">
              {project.technologies.map((tech, index) => (
                <li key={index} className="technology-item">{tech}</li>
              ))}
            </ul>
          </div>
          
          <div className="project-section">
            <h2>Key Features</h2>
            <ul className="features-list">
              {project.features.map((feature, index) => (
                <li key={index} className="feature-item">{feature}</li>
              ))}
            </ul>
          </div>
          
          <div className="project-links">
            {project.demoLink && (
              <a href={project.demoLink} target="_blank" rel="noopener noreferrer" className="project-link">
                <FaExternalLinkAlt /> View Demo
              </a>
            )}
            {project.githubLink && (
              <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="project-link">
                <FaGithub /> View on GitHub
              </a>
            )}
          </div>
        </div>
        <div className="project-image-container">
          <img src={project.image} alt={project.name} className="project-image" />
        </div>
      </div>
    </div>
  );
};

export default ProjectTemplate;
