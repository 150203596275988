export const experiences = [
  {
    title: "Lead Software Engineer",
    company: "Sidereus Space Dynamics",
    location: "Salerno, Italy",
    date: "Feb. 2024 - Present",
    description: "Spearheading the development of critical firmware for space systems, focusing on real-time operating systems like FreeRTOS and ARM processors. Leading a skilled software team in developing drivers and space-critical systems for the world's first single-stage rocket.",
  },
  // {
  //   title: "CTO",
  //   company: "Terravionics",
  //   location: "Rome, Italy",
  //   date: "Jan. 2020 - Mar. 2023",
  //   description: "Led a team of 5 engineers in developing innovative solutions in AI for precision Agricolture, embedded systems, and robotics using Agile methodologies.",
  // },
];

export const researchPapers = [
  {
    title: "Monitoring Vital Signs in Seated or Lying Positions",
    institution: "Assistive Technology Group (ATG) Lab, Politecnico di Milano",
    date: "Oct. 2022 - Mar. 2023",
    description: "Contributed to a comprehensive review paper analyzing over 150 articles on monitoring vital signs of individuals in seated or lying positions, providing a foundation for future innovations in healthcare and assistive devices.",
  },
];
