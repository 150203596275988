import React, { useState, useRef, useEffect } from 'react'; // Importa useState
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faBars } from '@fortawesome/free-solid-svg-icons'; // Import the menu icon
import { useNavigate, useLocation } from 'react-router-dom';  // Aggiungi Link qui

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(false); // Stato per la visibilità
  const navRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleNavbar = () => {
    setIsVisible(!isVisible); // Alterna la visibilità
  };

  const scrollToSection = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setIsVisible(false); // Chiude la navbar dopo aver cliccato un link
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav ref={navRef} className={`navbar ${isVisible ? 'visible' : ''}`}>
      <div className="navbar-content">
        <button onClick={toggleNavbar} className="navbar-toggle">
          <FontAwesomeIcon icon={faBars} className="fa-bars" />
        </button>
        {isVisible && (
          <ul className="navbar-menu">
            <li><button onClick={() => scrollToSection('home')} className="navbar-button">Home</button></li>
            <li><button onClick={() => scrollToSection('projects')} className="navbar-button">Projects</button></li>
            <li><button onClick={() => scrollToSection('experience')} className="navbar-button">Experience</button></li>
            <li><button onClick={() => scrollToSection('books')} className="navbar-button">Books</button></li>
            <li><button onClick={() => scrollToSection('recent-posts')} className="navbar-button">Blog</button></li>
            <li><button onClick={() => scrollToSection('contact')} className="navbar-button">Contact</button></li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;