import React from 'react';

function Header() {
  return (
    <header className="header">
      <div className="header-text">
        <h1>Marco De Vellis - Software Engineer</h1>
        {/* Specializing in Robotics and Space Systems Engineering */}
      </div>
    </header>
  );
}

export default Header;