import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeProvider';
import Home from './components/Home';
import BooksPage from './pages/BooksPage';
import RobotNavigationProject from './pages/RobotNavigationProject';
import Navbar from './components/Navbar';
import './styles/global.css';
import './styles/components.css';
import './styles/theme.css';
import DynamisProject from './pages/Dynamis';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';  // Aggiungi questa linea
import ThisProject from './pages/This';

function App() {
  return (
    <ThemeProvider defaultTheme="dark">
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/books" element={<BooksPage />} />
            <Route path="/projects/robot-navigation" element={<RobotNavigationProject />} />
            <Route path="/projects/Dynamis" element={<DynamisProject />} />
            <Route path="/projects/This" element={<ThisProject />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />  {/* Aggiungi questa linea */}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
