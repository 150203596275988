import React from 'react';
import ContactForm from './ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  return (
    <section className="contact-section bottom-spacing" id="contact"> {/* Aggiunto ID per la sezione Contact */}
      <h2 className="section-title">Let's Connect!</h2>
      <p>Interested in cool robotics projects? Get in touch!</p>
      <div className="contact-content">
        <ContactForm />
        <div className="contact-links">
          <a href="https://github.com/Hitomamacs" target="_blank" rel="noopener noreferrer" className="contact-link">
            <FontAwesomeIcon icon={faGithub} /> GitHub: @Hitomamacs
          </a>
          <a href="mailto:macsdeve@gmail.com" className="contact-link">
            <FontAwesomeIcon icon={faEnvelope} /> Email: macsdeve@gmail.com
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;