import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlog, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const RecentBlogPosts = () => {
  console.log('RecentBlogPosts component is rendering');
  const [recentPosts, setRecentPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Fetching blog posts...');
    fetch('/posts/index.json')
      .then(response => {
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched data:', data);
        const sortedPosts = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setRecentPosts(sortedPosts.slice(0, 3));
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error loading blog index:', error);
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  console.log('isLoading:', isLoading);
  console.log('error:', error);
  console.log('recentPosts:', recentPosts);

  if (isLoading) {
    return <div>Loading recent blog posts...</div>;
  }

  if (error) {
    return <div>Error loading blog posts: {error}</div>;
  }

  if (recentPosts.length === 0) {
    return <div>No recent blog posts available.</div>;
  }

  return (
    <section className="recent-blog-posts-section" id="recent-posts">
      <div className="section-banner">
        <h2>
          Recent Blog Posts
          <FontAwesomeIcon icon={faBlog} className="icon" style={{ marginLeft: '10px' }} />
        </h2>
      </div>
      <div className="recent-blog-posts-container">
        <div className="recent-posts-grid">
          {recentPosts.map((post) => (
            <div key={post.slug} className="recent-post-item">
              <h3 className="post-title">
                <Link to={`/blog/${post.slug}`}>{post.title}</Link>
              </h3>
              <p className="post-date">
                <FontAwesomeIcon icon={faCalendarAlt} /> {post.date}
              </p>
              <p className="post-excerpt">{post.excerpt}</p>
              <Link to={`/blog/${post.slug}`} className="read-more">Read More</Link>
            </div>
          ))}
        </div>
        <div className="view-all-posts">
          <Link to="/blog" className="view-all-button">View All Posts</Link>
        </div>
      </div>
    </section>
  );
};

export default RecentBlogPosts;
