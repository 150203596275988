export const projects = [
  {
    name: 'Leonardo Drone Contest',
    description: 'Implemented a ROS2-based navigation system for mobile robots using SLAM and path planning algorithms both for a ground robot and a UAV.',
    image: '/img/file.png',
    link: '/projects/robot-navigation',
  },
  {
    name: 'Dynamis PRC',
    description: 'Worked as a Firmware Engineer at Dynamis the Formula Student team of Politecnico di Milano.',
    image: '/img/car4.jpg',
    link: '/projects/Dynamis',
  },
  {
    name: 'This Website',
    description: 'Learning Web-Development for the first Time Starting from this website',
    image: '/img/web.png',
    link: '/projects/This',
  },
];
