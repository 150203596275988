import React from 'react';
import Header from './Header';
import Greeting from './Greeting';
import Skills from './Skills';
import Projects from './Projects';
import ExperienceAndResearch from './ExperienceAndResearch';
import RecommendedBooks from './RecommendedBooks';
import RecentBlogPosts from './RecentBlogPosts';
import Contact from './Contact';
import ThemeToggle from './ThemeToggle';

const Home = () => {
  return (
    <div className="home" id="home">
      <ThemeToggle />
      <div className="header-greeting-container" id="about">
        <Header />
        <Greeting />
      </div>
      <Skills id="skills" />
      <Projects id="projects" />
      <ExperienceAndResearch id="experience" />
      <RecommendedBooks id="books" />
      <RecentBlogPosts id="recent-posts" />  {/* Spostato qui */}
      <Contact id="contact" />
    </div>
  );
};

export default Home;