import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlog, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/posts/index.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPosts(data.sort((a, b) => new Date(b.date) - new Date(a.date)));
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error loading blog index:', error);
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <div className="blog-loading">Loading blog posts...</div>;
  if (error) return <div className="blog-error">Error: {error}</div>;

  return (
    <div className="blog-container">
      <div className="section-banner">
        <h1>
          <FontAwesomeIcon icon={faBlog} className="icon" /> Blog
        </h1>
      </div>
      <div className="blog-posts-grid">
        {posts.map((post) => (
          <article key={post.slug} className="blog-post-preview">
            <h2><Link to={`/blog/${post.slug}`}>{post.title}</Link></h2>
            <p className="post-date">
              <FontAwesomeIcon icon={faCalendarAlt} /> {post.date}
            </p>
            <p className="post-excerpt">{post.excerpt}</p>
            <Link to={`/blog/${post.slug}`} className="read-more">Read More</Link>
          </article>
        ))}
      </div>
    </div>
  );
};

export default Blog;
