import React from 'react';
import { FaRobot, FaBrain, FaRocket, FaLightbulb, FaSatellite } from 'react-icons/fa';

const Passions = () => {
  const passions = [
    { icon: <FaBrain />, text: 'AI' },
    { icon: <FaRobot />, text: 'Robotics' },
    { icon: <FaLightbulb />, text: 'Startups' },
    { icon: <FaSatellite />, text: 'Space' },
  ];

  return (
    <div className="passions-section">
      <h3>Passionate about:</h3>
      <div className="passions-container">
        {passions.map((passion, index) => (
          <div key={index} className="passion-item">
            <span className="passion-icon">{passion.icon}</span>
            <span className="passion-text">{passion.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Passions;
