import React, { useContext } from 'react';
import { FaRobot, FaCode, FaPython, FaBrain, FaMicrochip, FaDatabase, FaJava, FaCogs, FaTools } from 'react-icons/fa';
import { SiRos, SiJavascript } from 'react-icons/si';
import { ThemeContext } from '../components/ThemeProvider';
import Lottie from 'lottie-react';
import programmerAnimation from '../animations/Programmer.json'; // Assicurati che il percorso sia corretto

function Skills() {
  const { theme } = useContext(ThemeContext);

  const skills = [
    { name: 'Embedded Systems', level: 9, icon: <FaMicrochip /> },
    { name: 'ROS2', level: 8, icon: <SiRos /> },
    { name: 'C/C++', level: 9, icon: <FaCode /> },
    { name: 'Python', level: 8, icon: <FaPython /> },
    { name: 'Machine Learning', level: 7, icon: <FaBrain /> },
    // { name: 'Databases', level: 8, icon: <FaDatabase /> },
    { name: 'Robotics', level: 8, icon: <FaRobot /> },
    // { name: 'Java', level: 8, icon: <FaJava /> },
    { name: 'Control Systems', level: 8, icon: <FaCogs /> },
  ];

  return (
    <section className={`skills-section ${theme}`}>
      <div className="skills-content">
        <div className="illustration">
          <Lottie animationData={programmerAnimation} />
        </div>
        <div className="skills-container">
          <div className="skills-banner">
            <h2>Skills</h2>
            <FaTools className="icon" />
          </div>
          <div className="skills-list">
            {skills.map((skill, index) => (
              <div key={index} className="skill">
                <div className="skill-name">
                  {skill.icon}
                  <span>{skill.name}</span>
                </div>
                <div className="skill-bar-container">
                  <div 
                    className="skill-bar"
                    style={{ width: `${skill.level * 10}%` }}
                  ></div>
                </div>
                <div className="skill-score">{skill.level}/10</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;