import React from 'react';
import { experiences, researchPapers } from '../data/experienceData';

function ExperienceAndResearch() {
  return (
    <section className="experience-research-section" id="experience">
      <h2>Experience & Research</h2>
      <div className="experience-research-container">
        {experiences.map((exp, index) => (
          <div key={index} className="experience-item">
            <h3>{exp.title}</h3>
            <h4>{exp.company}</h4>
            <p>{exp.location} | {exp.date}</p>
            <p>{exp.description}</p>
          </div>
        ))}
        {researchPapers.map((paper, index) => (
          <div key={index} className="research-item">
            <h3>{paper.title}</h3>
            <h4>{paper.institution}</h4>
            <p>{paper.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ExperienceAndResearch;
